export default function useCachedData<T>(
  pathname: keyof typeof apiPaths | null,
  params = {},
) {
  const app = useNuxtApp()
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  return computed(() => {
    const pathParams = { orgId: org.value?.id, ...params }
    // @ts-ignore
    const cacheKey = apiPaths[pathname](pathParams, {
      org: org.value,
      user: user.value,
    })

    if (!app.payload.data[cacheKey]) {
      const empty = { data: { value: null } }
      return empty
    } else {
      return useNuxtData<T>(cacheKey)
    }
  })
}
